<template lang="">
  <v-dialog
    ref="dialog"
    v-model="modal"
    close-delay="500"
    :return-value.sync="date"
    internal-activator
    persistent
    width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <base-text-field
        v-model="date"
        :label="$t('date')"
        prepend-inner-icon="mdi-calendar"
        readonly
        type="text"
        v-bind="attrs"
        v-on="on"
        hide-details="auto"
      />
    </template>
    <v-date-picker
      v-model="date"
      scrollable
      @change="$emit('update:reservationDate', date)"
      color="primary"
      :min="minDate"
      locale="es-es"
    >
      <v-spacer></v-spacer>
      <v-btn text color="primary" @click="modal = false"> Cancelar </v-btn>
      <v-btn text color="primary" @click="$refs.dialog.save(date)"> Ok </v-btn>
    </v-date-picker>
  </v-dialog>
</template>
<script>
export default {
  data: () => {
    return {
      date: undefined,
      minDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      modal: false,
    };
  },
};
</script>
